<template>
  <div class="account-number">
    <div class="account-left">
      <div>
        <el-form :model="searchForm" ref="submitForm" :rules="rules">
          <el-form-item prop="title">
            <el-input
              size="small"
              class="input-bottom"
              v-model="searchForm.title"
              placeholder="备注（建议填群标题）"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="url">
            <el-input
              size="small"
              class="input-bottom"
              v-model="searchForm.url"
              placeholder="群链接"
              clearable
            ></el-input>
          </el-form-item>
          <!-- <el-form-item>
            <el-input
              class="input-bottom"
              type="textarea"
              v-model="searchForm.remark"
              placeholder="备注"
              clearable
            ></el-input>
          </el-form-item> -->
        </el-form>

        <el-button
          type="primary"
          size="mini"
          style="width: 100%; margin-top: 10px; border-radius: 16px"
          @click="searchFun"
          :loading="loading"
        >
          提交创建
        </el-button>
        <el-button
          class="el-icon-refresh-left"
          size="mini"
          style="width: 100%; margin-top: 10px; border-radius: 16px;margin-left:0"
          @click="refresh"
          :loading="loading"
        >
          刷新
        </el-button>
      </div>
      <logPage
        title="提交群记录"
        ref="searchIndex2Log"
        height="calc(100% - 205px)"
        :logList="logList"
        showType="2"
        attr="title"
        @selectLog="selectLog"
      />
    </div>
    <div
      class="account-right"
      :style="{ background: list.length == 0 ? 'white' : '' }"
      v-loading="loading"
    >
      <!--  :infinite-scroll-disabled="false" -->
      <div
        class="right-box"
        v-infinite-scroll="gettelegramJoinChannel"
        :infinite-scroll-disabled="scrollDisabled"
        :infinite-scroll-distance="10"
        id="t-scroll-add-group"
      >
        <div class="item-box" v-for="(item, i) in list" :key="i">
          <div class="top">
            <img
              :src="
                item.user_photo
                  ? 'data:image/png;base64,' + item.user_photo
                  : require('@/assets/imgs/p.png')
              "
              alt=""
            />
            <div class="top-name">
              <div v-html="item.title" :title="item.title"></div>
            </div>
          </div>
          <div class="content">
            <el-descriptions :column="1" :colon="true">
              <el-descriptions-item label="群ID">
                {{ item.channelId }}
                <span
                  class="jianSuo"
                  v-if="item.state == 2"
                  @click="retrieval(item.channelId)"
                >
                  检索
                </span>
              </el-descriptions-item>
              <el-descriptions-item label="状态">
                <!-- <el-link v-if="item.state == 4">url异常(查询不到内容)</el-link>
                <el-link type="primary" v-else-if="item.state == 1">
                  正在处理
                </el-link>
                <el-link type="warning" v-else-if="item.state == 0">
                  待处理
                </el-link>
                <el-link type="success" v-else-if="item.state == 2">
                  加群成功
                </el-link>
                <el-link type="danger" v-else-if="item.state == 3">
                  加群失败
                </el-link>

                <el-link type="info" v-else-if="item.state == 5">
                  该群组已在监控范围，无需重复提交
                </el-link> -->
                <el-tag
                  :type="
                    item.state === 1
                      ? 'primary'
                      : item.state === 0
                      ? 'info'
                      : item.state === 2
                      ? 'success'
                      : item.state === 3
                      ? 'danger'
                      : item.state === 4
                      ? 'warning'
                      : 'info'
                  "
                  disable-transitions
                >
                  {{
                    item.state === 1
                      ? '正在处理'
                      : item.state === 0
                      ? '待处理'
                      : item.state === 2
                      ? '加群成功'
                      : item.state === 3
                      ? '加群失败'
                      : item.state === 4
                      ? 'url异常(查询不到内容)'
                      : '该群组已在监控范围，无需重复提交'
                  }}
                </el-tag>
              </el-descriptions-item>

              <el-descriptions-item label="群链接">
                <a :href="item.url" target="_blanck">{{ item.url }}</a>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="time">
            <label>提交时间：</label>
            <span>{{ item.createTime }}</span>
          </div>
          <div class="foot">
            <span class="el-icon-delete" @click="deleteGroup(item.id)">
              删除
            </span>
          </div>
        </div>
        <li v-if="dataLoading">
          <span v-if="list.length > 0">加载中...</span>
        </li>
        <li v-else>
          <span v-if="list.length > 0">没有更多了...</span>
        </li>
      </div>
      <noData v-show="list.length == 0" />
    </div>
  </div>
</template>

<script>
import {
  telegramJoinChannel,
  telegramAddChannel,
  telegramDelChannel
} from '@/api/group'
import { scrollTopFun } from '@/plugins/logFunction'
import { getLocalStorageFun, localStorageFun } from '@/plugins/logFunction'
export default {
  name: 'AddGroup',
  data() {
    // 定义校验函数
    var emailTest = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('群链接不能为空！'))
      } else {
        const reg1 = /^http[s]{0,1}:\/\/t.me\/([0-9a-zA-Z_]+)$/
        const reg2 = /^http[s]{0,1}:\/\/t.me\/joinchat\/([0-9a-zA-Z_-]+)$/
        const reg3 = /^http[s]{0,1}:\/\/t.me\/\+([0-9a-zA-Z_-]+)$/

        if (reg1.test(value)) {
          callback()
        } else if (reg2.test(value)) {
          callback()
        } else if (reg3.test(value)) {
          callback()
        } else {
          return callback(new Error('群链接格式不正确！'))
        }
      }
    }
    return {
      searchForm: {
        title: '',
        url: ''
        // remark: ''
      },
      params: {},
      current: 1,
      limit: 50,
      list: [],
      logList: [],
      dataLoading: true, //是否还有更多数据
      loading: false, //加载状态
      scrollDisabled: false,
      listScrollTop: 0,
      rules: {
        title: [{ required: true, message: '备注不能为空。', trigger: 'blur' }],
        url: [{ required: true, validator: emailTest, trigger: 'blur' }]
      }
    }
  },

  created() {
    this.logList = getLocalStorageFun('telegramGroup')
  },
  activated() {
    scrollTopFun('t-scroll-add-group', 'set', this.listScrollTop)
  },
  deactivated() {
    this.listScrollTop = scrollTopFun('t-scroll-add-group', 'get')
  },
  methods: {
    // 检索
    retrieval(id) {
      this.$router.push('/group/detail/' + id)
    },
    gettelegramJoinChannel() {
      this.loading = true
      this.scrollDisabled = true
      this.params = Object.assign({}, this.searchForm)
      return new Promise((resolver, reject) => {
        telegramJoinChannel(this.current, this.limit, this.params).then(
          data => {
            this.loading = false
            if (data.items.length > 0) {
              this.list = this.list.concat(data.items)
              this.current++
              this.scrollDisabled = false

              if (data.items.length < this.limit) {
                this.dataLoading = false
              }
            } else {
              this.dataLoading = false
            }
            resolver()
          }
        )
      })
    },
    searchFun() {
      this.$refs['submitForm'].validate(valid => {
        if (valid) {
          this.dataLoading = true
          this.scrollDisabled = true
          this.$refs.searchIndex2Log.selectIndex = null
          this.current = 1
          this.list = []
          this.addGroup().then(res => {
            this.$message.success('创建成功！')
            this.searchForm.title = ''
            this.searchForm.url = ''
            this.$nextTick(() => {
              this.$refs.submitForm.resetFields()
            })

            this.gettelegramJoinChannel()
          })
          localStorageFun('telegramGroup', this.searchForm)
          this.logList = getLocalStorageFun('telegramGroup')
        }
      })
    },
    addGroup() {
      return new Promise((resolve, reject) => {
        telegramAddChannel(this.searchForm).then(res => {
          this.searchForm.channelUsername = ''
          this.searchForm.url = ''
          this.$nextTick(() => {
            this.$refs['submitForm'].clearValidate()
          })

          resolve(res)
        })
      })
    },
    // 刷新
    refresh() {
      this.dataLoading = true
      this.scrollDisabled = true
      this.$refs.searchIndex2Log.selectIndex = null
      this.current = 1
      this.list = []
      this.$nextTick(() => {
        this.$refs['submitForm'].resetFields()
      })
      this.gettelegramJoinChannel()
    },
    // 删除
    deleteGroup(id) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          telegramDelChannel(id).then(res => {
            this.dataLoading = true
            this.scrollDisabled = true
            this.$refs.searchIndex2Log.selectIndex = null
            this.current = 1
            this.list = []
            this.gettelegramJoinChannel()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    selectLog(row) {
      // this.searchForm = Object.assign({}, row)
      // this.dataLoading = true
      // this.scrollDisabled = true
      // this.current = 1
      // this.list = []
      // this.gettelegramJoinChannel().then(res => {
      //   this.scrollDisabled = false
      // })
      this.$refs.searchIndex2Log.selectIndex = null
    }
  }
}
</script>

<style lang="scss" scoped>
.account-number {
  width: 100%;
  height: calc(100vh - 79px);
  overflow: hidden;
  background: rgb(240, 242, 245);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  .account-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
    .input-bottom {
      margin-bottom: 10px;
    }
    .el-form-item {
      margin-bottom: 0px !important;
    }
    ::v-deep .el-form-item__error {
      top: 70% !important;
    }
  }
  .account-right {
    width: calc(100% - 270px);
    height: 100%;

    // border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding-left: 5px;
    position: relative;
    // overflow: hidden;
    .right-box {
      width: calc(100% + 10px);
      height: 100%;
      overflow: auto;
      .item-box {
        display: inline-block;
        width: calc(100% / 5 - 10px);
        margin: 0 10px 5px 0;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        padding: 10px;
        padding-bottom: 0;
        overflow: hidden;
        .top {
          display: flex;
          img {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            align-self: center;
          }
          .top-name {
            margin-left: 10px;
            align-self: center;
            white-space: nowrap;
            width: calc(100% - 50px);

            & > div {
              font-size: 14px;
              font-weight: 600;
              color: #1a1a1b;
              white-space: pre-wrap;
              //
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
        .content {
          white-space: nowrap;
          margin-top: 10px;
          .phone {
            text-decoration: underline;
            cursor: pointer;
            &:hover {
              color: rgb(0, 153, 255);
            }
          }
          .jianSuo {
            font-size: 16px;
            text-decoration: underline;
            font-weight: 600;
            cursor: pointer;
            color: #015fc9;
            margin-left: 10px;
          }
        }
        .time {
          width: 100%;
          height: 40px;
          border-top: 1px solid #f2f4f8;
          white-space: nowrap;
          label {
            font-size: 14px;
            font-weight: 400;
            color: #646363;
            line-height: 40px;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #646363;
            line-height: 40px;
          }
        }
        .foot {
          width: 100%;
          height: 40px;
          text-align: center;
          border-top: 1px solid #f2f4f8;
          span {
            line-height: 40px;
            font-size: 14px;
            font-weight: 600;
            color: #080808;
            cursor: pointer;
            user-select: none;

            &:hover {
              color: rgb(255, 0, 34);
            }
          }
        }
      }
    }
    li {
      list-style: none;
      text-align: center;
      color: #555555;
      font-size: 12px;
    }
  }
}
</style>
